<template>
  <div class="snt-login">
    <div class="snt-login__container">
      <div
        class="snt-login-card mx-auto"
        flat>
        <v-layout
          class="snt-login-card__container"
          wrap>
          <v-flex
            class="snt-login-cover"
            xs12
            md6
            lg5>
            <div class="snt-login-cover__container">
              <div class="snt-login-cover__subtitle">
                Create
              </div>
              <div class="snt-login-cover__title">
                Your template
              </div>
              <div class="snt-login-cover__img">
                <v-img
                  :src="mascotPNG"
                  height="212"
                  width="100" />
              </div>
            </div>
          </v-flex>
          <v-flex
            class="snt-login-form"
            xs12
            md6
            lg7>
            <div class="snt-login-form__logo">
              <v-img
                :src="whalePNG"
                height="50"
                width="38"
                contain />
            </div>
            <div class="snt-login-form__title">
              Account
            </div>
            <div class="snt-login-form__subtitle">
              Unlock your idea !
            </div>
            <div class="snt-login-form__label">
              Email
            </div>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              class="snt-login-form__field"
              name="email"
              autofocus
              outlined />
            <div class="snt-login-form__label">
              Password
            </div>
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              class="snt-login-form__field"
              name="password"
              outlined
              @click:append="toggleShowPassword()" />
            <div class="d-flex">
              <v-spacer />
              <v-btn
                color="primary"
                class="snt-login-form__submit"
                depressed
                x-large>
                Login
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>

import mascotPNG from '@/assets/img/mascot.png'
import whalePNG from '@/assets/img/whale.png'

export default {
  data () {
    return {
      mascotPNG,
      whalePNG,
      email: '',
      password: '',
      rules: {
        required: (v) => !!v || 'Required.'
      },
      showPassword: false
    }
  },
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>

<style scoped>
  @media (min-width: 960px) {
    .snt-login {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 820px;
    }
  }
  .snt-login__container {
    position: relative;
  }
  .snt-login-card {
    border-radius: 14px;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.12));
  }
  .snt-login-card__container {
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }
  .snt-login-cover {
    position: relative;
    color: #ffffff;
    background: #1BA7E1;
    min-height: 220px;
  }
  .snt-login-cover__container {
    border-radius: inherit;
    padding: 46px 32px;
    height: 100%;
  }
  .snt-login-cover__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36.29px;
    letter-spacing: 0.01em;
  }
  .snt-login-cover__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }
  .snt-login-cover__img {
    position: absolute;
    bottom: 0;
    right: 24px;
  }
  .snt-login-form {
    padding: 24px;
    background-color: #ffffff;
  }
  .snt-login-form__logo {
    width: 86px;
    height: 86px;
    background-color: #28ABDD;
    border-radius: 50%;
    margin: 0 auto 16px;
    padding: 16px;
    display: flex;
    align-items: center;
  }
  .snt-login-form__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 8px;
  }
  .snt-login-form__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #A8A8A8;
    text-align: center;
    margin-bottom: 24px;
  }
  .snt-login-form__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #6B6B6B;
    margin-bottom: 16px;
  }
  .snt-login-form__field {
    border-radius: 14px;
  }
  .snt-login-form__submit {
    border-radius: 14px;
  }
</style>
<style>
  .snt-login-form__field.v-text-field--outlined fieldset {
    border-width: 1px;
  }
</style>
